import GradientText from '@/components/GradientText';
import { Widget } from '@/components/Widget';
import { Box, Grid, styled, Typography } from '@mui/material';
import React from 'react';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';

const WidgetContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  width: 'fit-content',
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(10),
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
  },
}));

const TryMeImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '-100px',
  right: '-200px',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

export const Example = () => {
  return (
    <SectionContainer>
      <SectionTitle noMargin>Example</SectionTitle>
      <Typography variant="h2" textAlign="center">
        Simple and yet <GradientText>powerful</GradientText>
      </Typography>
      <WidgetContainer>
        <TryMeImage src="/images/landing/tryme.svg" alt="Try me" />
        <Widget id="R9hQm2I38qxzJMG" />
      </WidgetContainer>
    </SectionContainer>
  );
};
