import { styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const Title = styled(Typography)(({}) => ({
  letterSpacing: '0.4em',
  fontWeight: 700,
  lineHeight: '40px',
  textTransform: 'uppercase',
}));

export const SectionTitle = ({
  children,
  noMargin,
}: {
  children: ReactNode;
  noMargin?: boolean;
}) => {
  return (
    <Title
      sx={{ mb: noMargin ? 0 : { xs: 3, sm: 7 } }}
      textAlign="center"
      variant="h2"
      fontSize={20}
      gutterBottom
    >
      {children}
    </Title>
  );
};
