import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { Box, Button, Container, Grid, styled, Typography } from '@mui/material';
import GradientText from '@/components/GradientText';
import { StaticImage } from 'gatsby-plugin-image';

const Title = styled(Typography)``;

const TitleSection = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    maxWidth: '600px',
    margin: 'auto',
  },
}));

const HeroImageWrapperDesktop = styled('div')(({ theme }) => ({
  display: 'block',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const HeroImageWrapperMobile = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'block',
  },
}));

const ButtonGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    margin: 'auto',
  },
}));

const IntroSection = styled('main')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(8)} 0`,
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(5)} 0`,
    textAlign: 'center',
  },
  [theme.breakpoints.only('xs')]: {
    paddingTop: 0,
    paddingBottom: theme.spacing(4),
  },
}));

const HeroWrapper = styled('div')`
  overflow: hidden;
`;

const Center = styled('div')`
  margin: auto auto;
`;

export const Hero = () => {
  return (
    <HeroWrapper>
      <Container maxWidth="lg">
        <IntroSection>
          <Grid
            container
            gap={9}
            sx={{
              flexWrap: { sm: 'wrap', md: 'nowrap' },
            }}
          >
            <Grid item xs={12} sm={12} md={7}>
              <Center sx={{ ml: 0, mt: { lg: 7, md: 5, sm: 0 } }}>
                <TitleSection>
                  <Title gutterBottom variant="h1">
                    Know how your audience feels about your
                    <Box />
                    <ReactTypingEffect
                      text={['content.', 'ideas.']}
                      displayTextRenderer={(text, i) => {
                        return <GradientText key={i}>{text}</GradientText>;
                      }}
                      typingDelay={10}
                      eraseDelay={2000}
                      speed={100}
                      eraseSpeed={90}
                    />
                  </Title>
                  <Typography variant="subtitle1" sx={{ maxWidth: { lg: '500px' } }}>
                    Create embeddable widgets that drive engagement and email sign-ups.
                  </Typography>
                  <Box sx={{ display: 'flex' }} mt={8}>
                    <ButtonGrid>
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        href="https://app.pollpilot.io"
                      >
                        Create your free account
                      </Button>
                    </ButtonGrid>
                  </Box>
                </TitleSection>
              </Center>
            </Grid>
            <Grid container item xs={12} sm={12} md={5}>
              <HeroImageWrapperDesktop>
                <StaticImage
                  layout="fixed"
                  placeholder="blurred"
                  src="../../../images/landing/macbook-screenshot-pollpilot.png"
                  alt="Pollpilot"
                  height={700}
                />
              </HeroImageWrapperDesktop>
              <HeroImageWrapperMobile>
                <StaticImage
                  layout="constrained"
                  placeholder="blurred"
                  src="../../../images/landing/macbook-screenshot-pollpilot.png"
                  alt="Pollpilot"
                  height={500}
                />
              </HeroImageWrapperMobile>
            </Grid>
          </Grid>
        </IntroSection>
      </Container>
    </HeroWrapper>
  );
};
