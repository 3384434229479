import GradientText from '@/components/GradientText';
import React from 'react';
import { SectionContainer } from '../../components/SectionContainer';
import { SectionTitle } from '../../components/SectionTitle';
import { Step } from './Step';
import { Box, Button, Grid, Hidden, styled } from '@mui/material';
import { Center } from '@/components/Center';
import { StaticImage } from 'gatsby-plugin-image';

const IMAGE_HEIGHT = 270;

const Row = styled(Grid)(({ theme }) => ({
  display: 'flex',
  minHeight: `${IMAGE_HEIGHT}px`,
  [theme.breakpoints.only('xs')]: {
    flexWrap: 'wrap',
    marginTop: theme.spacing(10),
  },
}));

const CenterLeft = styled(Center)(({}) => ({
  marginLeft: 0,
}));

const CenterRight = styled(Center)(({ theme }) => ({
  marginRight: 0,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const CenterLeftHidden = styled(CenterLeft)(({ theme }) => ({
  marginRight: 0,
  [theme.breakpoints.up('xs')]: {
    display: 'none',
  },
}));

const ArrowWrapper = styled(Center)(({ theme }) => ({
  margin: `${theme.spacing(7)} auto`,
  height: '40px',
}));

export const Steps = () => {
  return (
    <SectionContainer>
      <SectionTitle>How it works</SectionTitle>
      <Box sx={{ mb: { xs: 16, sm: 12 } }} />
      <Row container>
        <Grid item container xs={12} sm={4} lg={6} sx={{ mb: { xs: 2 } }}>
          <CenterLeft>
            <Step
              title={
                <>
                  Ask your audience and <GradientText>get to know them</GradientText>
                </>
              }
              description="Adding polls to your content allows you to gain insights into your audiences opinions."
              stepNumber={1}
            />
          </CenterLeft>
        </Grid>
        <Grid item container xs={12} sm={8} lg={6}>
          <Box sx={{ m: { xs: 'auto auto auto 0', sm: 'auto 0 auto auto' } }}>
            <StaticImage
              src="../../../../images/landing/questions.png"
              placeholder="blurred"
              height={IMAGE_HEIGHT}
              alt="Example questions"
            />
          </Box>
        </Grid>
        <Grid item container xs={12}>
          <ArrowWrapper>
            <Hidden smDown implementation="css">
              <img src="/images/landing/arrow-left.svg" width={180} />
            </Hidden>
          </ArrowWrapper>
        </Grid>
      </Row>
      <Row container>
        <Grid item container xs={12} sm={8} lg={6} sx={{ order: { xs: 2, sm: 1 } }}>
          <CenterLeft>
            <StaticImage
              placeholder="blurred"
              src={'../../../../images/landing/feedback-cloud.png'}
              alt={'Feedback example cloud'}
              height={IMAGE_HEIGHT}
            />
          </CenterLeft>
        </Grid>
        <Grid item container xs={12} sm={4} lg={6} sx={{ order: { xs: 1, sm: 2 }, mb: { xs: 2 } }}>
          <Box
            sx={{
              m: 'auto',
              mr: { xs: 'auto', sm: 0 },
              ml: { xs: 0, sm: 'auto' },
              textAlign: { xs: 'left', sm: 'right' },
            }}
          >
            <Step
              title={
                <>
                  Collect additional <GradientText>feedback</GradientText>
                </>
              }
              description="Get further insights into what does and doesn’t work by asking follow-up questions."
              stepNumber={2}
            />
          </Box>
        </Grid>
        <Grid item container xs={12} sx={{ order: 3 }}>
          <ArrowWrapper>
            <Hidden smDown implementation="css">
              <img src="/images/landing/arrow-right.svg" width={180} />
            </Hidden>
          </ArrowWrapper>
        </Grid>
      </Row>
      <Row container>
        <Grid item container xs={12} sm={4} lg={6} sx={{ mb: { xs: 2 } }}>
          <CenterLeft>
            <Step
              title={
                <>
                  <GradientText>Convert</GradientText> your audience into subscribers
                </>
              }
              description="Ask respondants to sign up to your newsletter after they answered a poll."
              stepNumber={3}
            />
          </CenterLeft>
        </Grid>
        <Grid item container xs={12} sm={8} lg={6}>
          <Grid item container xs={12}>
            <CenterRight>
              <StaticImage
                placeholder="blurred"
                src={'../../../../images/landing/subscribers.png'}
                alt={'Email signup form'}
                height={IMAGE_HEIGHT}
              />
            </CenterRight>
            <CenterLeftHidden>
              <StaticImage
                placeholder="blurred"
                src={'../../../../images/landing/subscribers-2.png'}
                alt={'Email signup form'}
                height={IMAGE_HEIGHT}
              />
            </CenterLeftHidden>
          </Grid>
        </Grid>
      </Row>
      <Grid container>
        <Button
          sx={{ m: 'auto', mt: { sm: 7 } }}
          variant="contained"
          size="large"
          onClick={() => (window.location.href = 'https://app.pollpilot.io')}
        >
          Create your first poll
        </Button>
      </Grid>
    </SectionContainer>
  );
};
