import { CenterLeft } from '@/components/Center';
import { Box, Grid, Hidden, Link, styled, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';

const IMG_HEIGHT = 540;

const TestimonalImage = styled('div')(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: `calc(50% - ${IMG_HEIGHT}px/ 2 + 80px)`,
  marginLeft: 'auto',
  marginRight: 0,
  zIndex: -1,
  [theme.breakpoints.down('lg')]: {
    //position: 'inherit',
    //margin: 'auto 0 auto auto',
    //zIndex: 1,
    top: '200px',
  },
  [theme.breakpoints.down('md')]: {
    top: '450px',
  },

  [theme.breakpoints.only('xs')]: {
    top: '180px',
    right: '-90px',
    opacity: 0.5,
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  marginBottom: theme.spacing(3),
}));

const TestimonialContainer = styled(SectionContainer)({});

const TestimonialTextWrapper = styled(Grid)(({ theme }) => ({
  position: 'relative',
  minHeight: `${IMG_HEIGHT}px`,
  [theme.breakpoints.only('xs')]: {
    minHeight: 'auto',
    marginTop: '90px',
  },
}));

const UnstyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'inherit',
});

const Quote = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: '50px',
  left: '-100px',
  fontSize: '400px',
  mixBlendMode: 'soft-light',
  opacity: 0.3,
  [theme.breakpoints.down('lg')]: {
    left: '-10px',
    top: '10px',
  },
  [theme.breakpoints.only('xs')]: {
    display: 'none',
  },
}));

export const Testimonials = () => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', flexWrap: 'wrap', overflow: 'hidden' }}>
      <TestimonialContainer>
        <SectionTitle>Testimonials</SectionTitle>
        <Grid container>
          <TestimonalImage>
            <Hidden lgDown implementation="css">
              <StaticImage
                src="../../../images/landing/tiago-testimonial.jpg"
                height={IMG_HEIGHT}
                alt="Photo of Tiago"
              />
            </Hidden>
            <Hidden lgUp mdDown implementation="css">
              <StaticImage
                src="../../../images/landing/tiago-testimonial.jpg"
                height={400}
                alt="Photo of Tiago"
              />
            </Hidden>
            <Hidden mdUp implementation="css">
              <StaticImage
                src="../../../images/landing/tiago-testimonial.jpg"
                height={200}
                alt="Photo of Tiago"
              />
            </Hidden>
          </TestimonalImage>
          <TestimonialTextWrapper container item xs={12} sm={12}>
            <Quote src="/images/landing/quote.svg"></Quote>
            <CenterLeft>
              <Title variant="h2">I love to know that people are liking my articles...</Title>
              <Box sx={{ maxWidth: 450 }}>
                <Typography variant="subtitle1" paragraph>
                  ...and so far I've had 6 responses to my articles, all being positive.
                </Typography>
                <Typography variant="subtitle1" paragraph>
                  For a blogger, this is good because "numbers" of visitors don't always show
                  satisfaction.
                </Typography>
                <br />
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 'bold' }}
                  paragraph
                  color="text.secondary"
                >
                  — Tiago Silva,{' '}
                  <UnstyledLink target="_blank" rel="noopener" href="https://thestackjunction.com">
                    thestackjunction.com
                  </UnstyledLink>
                </Typography>
              </Box>
            </CenterLeft>
          </TestimonialTextWrapper>
        </Grid>
      </TestimonialContainer>
    </Box>
  );
};
