import GradientText from '@/components/GradientText';
import { Card, CardContent, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { SectionContainer } from '../components/SectionContainer';
import { SectionTitle } from '../components/SectionTitle';

const CardTitle = styled(Typography)<{ [key: string]: any }>(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

const FeatureCard = ({
  children,
  emoji,
  title,
}: {
  children: ReactNode;
  emoji: string;
  title: string;
}) => (
  <Card variant="outlined">
    <CardContent sx={{ p: 3 }}>
      <Typography variant="h2" component="h5" sx={{ mb: 1 }}>
        {emoji}
      </Typography>
      <CardTitle variant="h2" component="h3">
        {title}
      </CardTitle>
      <Typography paragraph color="text.secondary" component="span">
        {children}
      </Typography>
    </CardContent>
  </Card>
);

const FeatureGrid = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  marginTop: theme.spacing(7),
  justifyContent: 'center',
  maxWidth: '100%',
  overflow: 'hidden',
  flexWrap: 'wrap',
  div: {
    flex: '0 0 calc(33.33% - 20px)',
    [theme.breakpoints.down('md')]: {
      flex: '0 0 calc(50% - 20px)',
    },
    [theme.breakpoints.down('md')]: {
      flex: '1 0 100%',
    },
  },
}));

export const Features = () => {
  return (
    <SectionContainer>
      <SectionTitle noMargin>Features</SectionTitle>
      <Typography variant="h2" textAlign="center">
        Everything you need to <GradientText>understand</GradientText> your audience.
      </Typography>
      <FeatureGrid>
        <FeatureCard emoji="🇪🇺" title="Based in Europe">
          All data is stored securely in the EU. We never share or sell your data with third-party
          providers.
        </FeatureCard>
        <FeatureCard emoji="🍪" title="No cookie banner required">
          All widgets comply with GDPR and don’t require a cookie-banner.
        </FeatureCard>
        <FeatureCard emoji="🏎" title="Real-time data">
          See updates and responses live on your dashboard. Email notifications are coming soon.
        </FeatureCard>
        <FeatureCard emoji="⚡️" title="Fast and lightweight">
          Widgets are small in size and are stored on a global network.
        </FeatureCard>
        <FeatureCard emoji="❤️" title="Easy to integrate">
          Copy and paste one line of code. No programmers needed.
        </FeatureCard>
      </FeatureGrid>
    </SectionContainer>
  );
};
