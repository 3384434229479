import { Grid, styled, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import { SectionContainer } from '../components/SectionContainer';

const IntegrationIconGrid = styled(Grid)`
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing(7)};
`;

const IconWrapper = styled(Grid)<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.1 : 0.6)};
  display: flex;
  .gatsby-image-wrapper {
    margin: auto;
    ${({ theme }) => theme.breakpoints.down('sm')} {
      width: 40px;
    }
  }
`;

const IntegrationIcon = ({ children, disabled }: { children: ReactNode; disabled?: boolean }) => {
  return (
    <IconWrapper item disabled={disabled}>
      {children}
    </IconWrapper>
  );
};

export const Integrations = () => {
  return (
    <SectionContainer>
      <IntegrationIconGrid container>
        <IntegrationIcon>
          <StaticImage
            placeholder="blurred"
            src={'../../../images/landing/code.png'}
            alt={'Code icon'}
            height={40}
          />
        </IntegrationIcon>
        <IntegrationIcon>
          <StaticImage
            placeholder="blurred"
            src={'../../../images/landing/wordpress.png'}
            alt={'Wordpress icon'}
            height={70}
          />
        </IntegrationIcon>
        <IntegrationIcon disabled>
          <StaticImage
            placeholder="blurred"
            src={'../../../images/landing/medium.png'}
            alt={'Medium icon'}
            height={40}
          />
        </IntegrationIcon>
        <IntegrationIcon disabled>
          <StaticImage
            placeholder="blurred"
            src={'../../../images/landing/substack.png'}
            alt={'Substack icon'}
            height={70}
          />
        </IntegrationIcon>
        <IntegrationIcon disabled>
          <StaticImage
            src={'../../../images/landing/email.png'}
            placeholder="blurred"
            alt={'Email icon'}
            height={40}
          />
        </IntegrationIcon>
      </IntegrationIconGrid>
      <Typography color="text.secondary" textAlign="center">
        Works on Wordpress, Squarespace, and many more.
      </Typography>
      <Typography paragraph color="text.secondary" textAlign="center">
        Support for Email and Medium is coming soon.
      </Typography>
    </SectionContainer>
  );
};
