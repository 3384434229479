import { styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const StepWrapper = styled('div')(({}) => ({
  maxWidth: '400px',
  position: 'relative',
  zIndex: 1,
}));

const StepNumber = styled(Typography)(({ theme }) => ({
  fontSize: '14rem',
  fontStyle: 'normal',
  color: '#E7EBF0',
  opacity: 0.05,
  zIndex: -1,
  fontWeight: 700,
  position: 'absolute',
  top: '-10rem',
  left: '-4rem',
  [theme.breakpoints.down('lg')]: {
    fontSize: '12rem',
    top: '-11rem',
    left: '-0rem',
  },
}));

export const Step = ({
  title,
  description,
  stepNumber,
  className,
}: {
  title: ReactNode;
  description: string;
  stepNumber: number;
  className?: string;
}) => {
  return (
    <StepWrapper className={className}>
      <Typography gutterBottom variant="h2">
        {title}
      </Typography>
      <Typography paragraph color="text.secondary">
        {description}
      </Typography>
      <StepNumber variant="body1">{stepNumber}</StepNumber>
    </StepWrapper>
  );
};
