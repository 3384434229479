import { Container } from '@mui/material';
import React, { ReactNode } from 'react';

export const SectionContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <Container className={className} maxWidth="lg" sx={{ pb: 8, pt: 8 }}>
      {children}
    </Container>
  );
};
