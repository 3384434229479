import { Layout } from '@/components/Layout';
import Helmet from 'react-helmet';
import React from 'react';
import { Hero } from './sections/Hero';
import { Integrations } from './sections/Integrations';
import { GradientBackground } from './components/GradientBackground';
import { Steps } from './sections/Steps';
import { Testimonials } from './sections/Testimonials';
import { Example } from './sections/Example';
import { Features } from './sections/Features';
import { CTA } from './sections/CTA';

export const LandingPage = () => {
  return (
    <Layout lightFooter>
      <Helmet title="PollPilot | Embeddable Polls and Reactions" />
      <GradientBackground>
        <Hero />
        <Integrations />
      </GradientBackground>
      <GradientBackground inverse>
        <Steps />
      </GradientBackground>
      <Testimonials />
      <Example />
      <GradientBackground>
        <Features />
        <CTA />
      </GradientBackground>
    </Layout>
  );
};
