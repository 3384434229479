import { FreeTrialCTA } from '@/components/FreeTrialCTA';
import React from 'react';
import { SectionContainer } from '../components/SectionContainer';

export const CTA = () => {
  return (
    <SectionContainer>
      <FreeTrialCTA />
    </SectionContainer>
  );
};
